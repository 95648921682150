<template>
  <v-container fluid>
    <PageToolbar 
    :title="$tc('global.caixa', 2)" 
    icon="mdi-cash-register" 
    dark 
    />

    <v-card min-height="50vh">
      <v-card-text class="pa-6">
        <DialogAbrirCaixa
          v-if="dialogAbrirCaixa"
          :dialogAbrirCaixa.sync="dialogAbrirCaixa"
          :caixa_id="caixa_id"
        />
        <v-row>
          <v-col v-for="(caixa, i) in caixas" :key="i" md="4" cols="12">
            <v-card outlined @click="goToCaixa(caixa)">
              <v-card-title 
              class="tocs_gray_1" 
              style="min-height: 80px !important"
              >
                <span class="text-h6">{{ caixa.descricao }}</span>
                <div v-if="caixa.tipo_caixa === 'PDV'" class="ml-4 d-flex flex-column">
                  <span class="text-caption">{{ $tc('global.ultimoFechamento') }}: {{ caixa.quem_fechou }} </span>
                  <span v-if="caixa.caixa_aberto" class="text-caption">{{ $tc('global.abertoPor') }}: {{ caixa.quem_abriu }} </span>
                </div>
                <v-spacer></v-spacer>
                <span v-if="caixa.tipo_caixa === 'PDV'">
                  <v-chip
                    small
                    :color="caixa.caixa_aberto ? 'green' : 'red'"
                    text-color="white"
                  >
                    {{
                      caixa.caixa_aberto 
                      ? $tc("global.aberto") 
                      : $tc("global.fechado")
                    }}
                  </v-chip>
                </span>

                <v-tooltip bottom color="darkblue">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="ml-1"
                      v-if="caixa.tipo_caixa === 'PDV' && caixa.caixa_aberto"
                      large
                      icon
                      v-on="on"
                      @click.stop="dialogCaixaAbertoVisualizacaoOpen(caixa)"
                    >
                      <v-icon> mdi-text-box-search-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $tc("global.verificar") }} {{ $tc("global.transacoes") }} </span>
                </v-tooltip>

                <v-tooltip bottom color="darkblue">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="caixa.tipo_caixa !== 'PDV' || caixa.caixa_aberto && PCaixaAfVisualizar"
                      large
                      icon
                      @click.stop="mostrarCaixa(i)"
                      v-on="on"
                    >
                      <v-icon>
                        {{ 
                        mostrarMais && indexCaixa === i 
                        ? "mdi-eye" 
                        : "mdi-eye-off" 
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $tc("global.verificar") }} {{ $tc("global.saldocaixa") }} </span>
                </v-tooltip>

                <v-tooltip bottom color="darkblue">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="caixa.tipo_caixa === 'PDV' && caixa.caixa_aberto && PDialogFechamentoCaixa"
                      large
                      icon
                      v-on="on"
                      @click.stop="fecharCaixa(caixa)"
                    >
                      <v-icon>
                        {{ 
                          "mdi-close-octagon" 
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $tc("global.fechar") }} {{ $tc("global.caixa") }}</span>
                </v-tooltip>
                
              </v-card-title>
              <v-divider></v-divider>
              <v-slide-y-transition>
                <v-card-text flat v-show="mostrarMais && indexCaixa === i">
                  <div v-if="caixa.totais">
                    <div>
                      <span class="d-flex text-subtitle-1 font-weight-bold">
                        G$
                        <v-spacer></v-spacer>
                        {{ caixa.totais.saldo_guarani | guarani }}
                      </span>
                      <v-divider class="mb-2"></v-divider>
                      <span class="d-flex text-subtitle-1 font-weight-bold">
                        R$
                        <v-spacer></v-spacer>
                        {{ caixa.totais.saldo_real | currency }}
                      </span>
                      <v-divider class="mb-2"></v-divider>
                      <span class="d-flex text-subtitle-1 font-weight-bold">
                        U$ <v-spacer></v-spacer>
                        {{ caixa.totais.saldo_dolar | currency }}
                      </span>
                      <v-divider class="mb-2"></v-divider>
                      <span class="d-flex text-subtitle-1 font-weight-bold">
                        Crédito G$
                        <v-spacer></v-spacer>
                        {{ caixa.totais.saldo_credito | guarani }}
                      </span>
                      <v-divider class="mb-2"></v-divider>
                      <span class="d-flex text-subtitle-1 font-weight-bold">
                        Débito G$
                        <v-spacer></v-spacer>
                        {{ caixa.totais.saldo_debito | guarani }}
                      </span>
                    </div>
                  </div>
                  <div
                    v-else-if="caixa.tipo_caixa === 'PDV' && !caixa.totais"
                    class="d-flex justify-center pa-4"
                  >
                    {{ $tc("global.caixa") }} {{ $tc("global.aberto") }}
                  </div>
                </v-card-text>
              </v-slide-y-transition>
            </v-card>
          </v-col>
          <v-col
            v-if="!caixas.length"
            class="pa-6 text-h6 align-center d-flex justify-center"
          >
            {{ $tc("global.caixaSemVinculo") }}
          </v-col>
        </v-row>
        <DialogFechamentoCaixa
          v-if="dialogFechamentoCaixa"
          :dialogFechamentoCaixa.sync="dialogFechamentoCaixa"
          :caixa_af_id="caixa_fechar_id"
          @fetch-caixas="getCaixas"
        />
        <DialogCaixaAbertoVisualizacao
          v-if="dialogCaixaAbertoVisualizacao"
          :dialogCaixaAbertoVisualizacao.sync="dialogCaixaAbertoVisualizacao"
          :caixa_pdv_id="caixa_pdv_visualizacao"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { fetchCaixasAcesso } from "@/api/caixas/caixas.js";
import {
  usuarioCaixaAberto,
  fetchCaixaAfUser,
  fetchUltimoCaixaAf,
} from "@/api/caixas/caixas_af.js";
import { mapGetters } from "vuex";
export default {
  name: "CaixasAcesso",

  components: {
    DialogAbrirCaixa: () => import("./components/DialogAbrirCaixa.vue"),
    DialogCaixaAbertoVisualizacao: () =>
      import("./components/DialogCaixaAbertoVisualizacao.vue"),
    DialogFechamentoCaixa: () =>
      import("../caixas-pdv/components/DialogFechamentoCaixa.vue"),
  },

  data() {
    return {
      caixa: {},
      dialogCaixas: false,
      loading: false,
      caixas: [],
      caixa_original: {},
      mostrarMais: false,
      indexCaixa: null,
      usuarioHasCaixaAberto: null,
      dialogAbrirCaixa: false,
      caixa_id: null,
      allow_user: null,
      timbrados: [],
      dialogFechamentoCaixa: false,
      caixa_fechar_id: null,
      caixa_pdv_visualizacao: null,
      dialogCaixaAbertoVisualizacao: false,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    PCaixaVisualizar() {
      return this.getAccess("Caixas", "visualizar");
    },
    PDialogFechamentoCaixa() {
      return this.getAccess("DialogFechamentoCaixa", "visualizar");
    },
    PCaixaAfVisualizar() {
      return this.getAccess("CaixasAf", "visualizar");
    },
  },

  methods: {
    dialogCaixaAbertoVisualizacaoOpen(caixa) {
      this.dialogCaixaAbertoVisualizacao = true;
      this.caixa_pdv_visualizacao = caixa.id;
    },
    mostrarCaixa(index) {
      this.mostrarMais = true;
      if (this.indexCaixa === index) {
        this.mostrarMais = false;
        this.indexCaixa = null;
      } else {
        this.indexCaixa = index;
      }
    },

    async goToCaixa(item) {
      if (this.PCaixaVisualizar) {
        if (item.tipo_caixa === "PDV") {
          if (item.caixa_aberto === false) {
            await this.usuarioCaixaAberto();
            if (!this.usuarioHasCaixaAberto) {
              this.dialogAbrirCaixa = true;
              this.caixa_id = item.id;
            } else {
              this.$toast.error(this.$tc("global.fecharCaixaAntes"));
            }
          } else {
            await this.getCaixaAfUser(item.id);
            if (this.allow_user === 1) {
              this.$router.push({ path: `/caixas-pdv/${item.id}` });
            } else if (this.allow_user === 2) {
              this.dialogFechamentoCaixa = true;
              this.caixa_id = item.id;
            } else {
              this.$toast.error(this.$tc("global.caixaAbertoOutroUsuario"));
            }
          }
        } else {
          this.$router.push({
            path: `/caixas/${item.id}/movimentacoes`,
          });
        }
      }
    },

    getCaixas() {
      this.loading = true;

      fetchCaixasAcesso()
        .then((response) => {
          this.caixas = response;
          this.caixa_original = { ...response };
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },

    getCaixaAfUser(item) {
      this.loading = true;

      return fetchCaixaAfUser(item)
        .then((response) => {
          this.allow_user = response.result;
          this.caixa_fechar_id = response.caixa_af_id;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    getUltimoCaixaAf(caixa) {
      this.loading = true;
      return fetchUltimoCaixaAf(caixa.id)
        .then((response) => {
          this.caixa_fechar_id = response.id;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    async usuarioCaixaAberto() {
      this.loading = true;
      await usuarioCaixaAberto()
        .then((response) => {
          this.usuarioHasCaixaAberto = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async fecharCaixa(caixa) {
      if (this.PDialogFechamentoCaixa) {
        await this.getUltimoCaixaAf(caixa);
        this.dialogFechamentoCaixa = true;
        this.caixa_id = caixa.id;
      }
    },
  },

  created() {
    this.getCaixas();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .session--active .v-icon {
  transform: rotate(-180deg);
}
</style>
